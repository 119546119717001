import { HiMenu } from 'react-icons/hi';

const Header = ({headerColor, handleLinkClick, sections, setShow}) => {
    return (
        <header className="header">
        <div className={`header-home ${headerColor === 'scroll' && 'background'}`}>
            <div className='max-width-1200 width-100'>
              <div className='nav-container'>
                <a className='nav-links hide-small' onClick={(e) => handleLinkClick(e, sections[''], '')} href='/'>Home</a>
                <a className='nav-links hide-small' onClick={(e) => handleLinkClick(e, sections['our-services'], 'our-services')} href='our-services'>What we Offer</a>
                <a className='nav-links hide-small' onClick={(e) => handleLinkClick(e, sections['success-stories'], 'success-stories')} href='success-stories'>Yoga Success Stories</a>
                <a className='nav-links hide-small' onClick={(e) => handleLinkClick(e, sections['about-us'], 'about-us')} href='about-us'>About Us</a>
                <a className='nav-links hide-small' onClick={(e) => handleLinkClick(e, sections['contact-us'], 'contact-us')} href='contact-us'>Contact Us</a>
                <div className='nav-links menu-icon'><HiMenu onClick={() =>  setShow(true)} size={30} /></div>
              </div>
              {headerColor !== 'scroll' && <hr className='divider' />}
            </div>
          </div>
      </header>
    )
}

export default Header;