/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import Home from './components/Academy';
import Services from './components/Offer';
import Stories from './components/Stories';
import AboutUs from './components/Address';
import ContactUs from './components/Inquire';
import Footer from './components/Yogasoul';
import Header from './components/Header';
import Menu from './components/Header/menu';

function App() {
  const homeRef = useRef();
  const aboutRef = useRef();
  const servicesRef = useRef();
  const storiesRef = useRef();
  const contactRef = useRef();
  const sections = {
    '': homeRef,
    'about-us': aboutRef,
    'our-services': servicesRef,
    'success-stories': storiesRef,
    'contact-us': contactRef,
  }
  const [headerColor, setHeaderColor] = useState("white");
  const [show, setShow] = useState(false);

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLinkClick = (event, ref, section) => {
    event.preventDefault();
    scrollToSection(ref);
    window.history.pushState(null, null, `/${section}`);
    setShow(false);
  };

  const listenScrollEvent = () => {
    window.scrollY > 10
      ? setHeaderColor("scroll")
      : setHeaderColor("default")
  }
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)
  });

  const location = window.location.pathname;

  useEffect(() => {
    const [, path] = location.split('/');
    if (Object.keys(sections).includes(path)) {
      scrollToSection(sections[path]);
    }
  }, [location, sections])

  return (
    <div>
      <Header headerColor={headerColor} handleLinkClick={handleLinkClick} setShow={setShow} sections={sections} />
      <Menu show={show} setShow={setShow} handleLinkClick={handleLinkClick} sections={sections} />
      <div ref={homeRef}>
        <Home/>
      </div>
      <div ref={servicesRef}>
        <Services/>
      </div>
      <div ref={storiesRef}>
        <Stories/>
      </div>
      <div ref={aboutRef}>
        <AboutUs/>
      </div>
      <div ref={contactRef}>
        <ContactUs/>
      </div>
      <Footer/>
    </div>
  );
}

export default App;


