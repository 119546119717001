import React from 'react';
import StyledOffer from './index.styles';
const Offer = () => {
  return (
    <StyledOffer>
      <div className="offer">What We Offer</div>
      <div className="yoga-offer">
        <div className="yoga-container">
          <img  className="offer-image" src="\iyengar.jpg" alt="inquireimage"></img>
          <div className="h">Iyengar Yoga</div>
          <div className="a">
            Iyengar Yoga is a form of yoga as exercise with a focus on the structural alignment of the physical body through the practice of asanas. It differs from other styles of yoga in three ways: precision, sequence and use of props. Precision is sought in body alignment in every asana.
          </div>
        </div>
        <div className="yoga-container">
          <img  className="offer-image" src="\ashtanga.jpg" alt="inquireimage"></img>
          <div className="h">Ashtanga Yoga</div>
          <div className="a">
            Ashtanga is a very dynamic and athletic form of Hatha yoga, made up of six series or levels, with a fixed order of postures. It is rooted in vinyasa, the flowing movements between postures, with a focus on energy and breath. While it is a very physical practice, it also promotes mental clarity and inner peace.
          </div>
        </div>
        <div className="yoga-container">
          <img  className="offer-image" src="\vinyasa.jpg" alt="inquireimage"></img>
          <div className="h">Vinyasa Yoga</div>
          <div className="a">
            Vinyasa yoga is a creative form of yoga where poses are linked together with the breath in a flowing sequence. The beauty of Vinyasa yoga is the variety. There is no standard sequence in Vinyasa yoga, so the style, pace, and intensity will all vary depending on the teacher.
          </div>
        </div>
        <div className="yoga-container">
          <img  className="offer-image" src="\hatha.jpg" alt="inquireimage"></img>
          <div className="h">Hatha Yoga</div>
          <div className="a">
            A yoga class described as 'Hatha' will typically involve a set of physical postures (yoga poses) and breathing techniques. These are typically practiced more slowly and with more static posture holds than a Vinyasa flow or Ashtanga class.
          </div>
        </div>
      </div>
    </StyledOffer>
  );
};
export default Offer;