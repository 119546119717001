import styled from "styled-components";
// import Image from "../../yogaicon.png";

const StyledYogasoul = styled.div`
  background-color: #DDDBCA;

  .image {
    width: 100%;
    background-position: center;
  }
  .image-container {
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
  }
`;
export default StyledYogasoul;