import styled from "styled-components";
const StyledInquire = styled.div`
  background-color: #F7F3EB;

  .inquire {
    display: flex;
    flex-direction: row;
    padding: 10% 2%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .image {
    object-fit: contain;
    padding: 10px;
    width: 40%;
  }
  .personalized {
    display: flex;
    flex-direction: column;
    width: 40%;
    @media (max-width: 767px) {
      width: 80%;
      padding: 10px;
    }
  }
  .classes {
    font-size: 36px;;
  }
  .content {
    font-size: 18px;
    padding: 6% 0%;
  }
  .button {
    color: #62604E;
    background-color: #F7F3EB;
    padding: 2%;
    width: 50%;
    margin: 10px 0px;
    align-self: center;
    font-size: 20px;
  }
  .contact-us {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 80%;
    padding: 7%;
  }
  .label {
    font-size: 16px;
    padding: 10px 0px;
    color: #666666;
    gap: 10px;
    font-weight: 600;
  }
  .input {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
  }
  ::placeholder {
    text-decoration: none;
  }
  .image-carousel {
    width: 30%;
    @media (max-width: 767px) {
      width: 80%;
    }
  }

  /* Media Query for Screens with a Maximum Width of 768px */
  @media (max-width: 768px) {
    .inquire {
      flex-direction: column;
      padding: 40px;
    }
    .image {
      width: 100%;
      height: auto;
      margin-right: 0;
    }
    .content {
      width: 100%; /* Make the content width 100% on smaller screens */
    }
  }
  .flex-row {
    display: flex;
    flex-driection: row;
  }
  .flex-col {
    display: flex;
    flex-direction: column;
  }
  .error-text {
    color: #ef5771;
    font-size: .875rem;
    padding-left: 5px;
  }
  .success {
    color: #270;
    background-color: #DFF2BF;
    align-items: center;
    justify-content: center;
    padding: 5px;
    .success-icon {
      padding-right: 5px;
    }
  }
`;
export default StyledInquire;