import styled from "styled-components";
const StyledAddress = styled.div`
background-color: #DDDBCA;

.address{
    display:flex;
    flex-direction:row;
    padding: 8% 2%;
    align-items:center;
    margin: auto;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
  }
  .content {
    width: 50%;
    @media (max-width: 1000px) {
      width: 80%;
      margin-bottom: 5%;
    }
  }
  .image{
    object-fit:contain;
    display: flex;
    width: 90%;
  }
 .studio-container {
   display: flex;
   margin-top: 10%;
 }
 .Main{
  font-size: 25px;
  font-weight: bold;
  color: #3B3A2E;
  width: 35%;
  padding-right: 5%;
 }
 .city{
    display:flex;
    flex-direction:column;
    font-size: 16px;
    gap: 10px;
    width: 60%;
 }
 .heading{
    font-size:36px;
    color:#79765C;
    font-weight:bold;
    margin-top:35px;
    padding:50px;
 }
 .social{
   font-size: 30px;
 }
 .image-icon-container {
    width: 45%;
   display: flex;
   flex-direction: column;
   align-items: start;
 }
 .icons {
   display: flex;
   align-items: center;
 }
 .icons > * {
  padding-left: 10px;
 }
 .icon-container {
   display: flex;
   margin-top: 40px;
   gap: 20px;
 }
 .subtitle {
   font-weight:bold;
   color:#79765C;
   font-size: 20px
 }
 .mail {
  white-space: nowrap;
  text-overflow: ellipsis;
}
`;
export default StyledAddress;