import styled from "styled-components";
const StyledStories = styled.div`
  background-color: #F7F3EB;
  padding: 5%;
  align-items:center;
  
  .stories {
    font-size: 60px;
    padding: 60px;
  }
  .success {
    display: flex;
    padding: 30px;
    line-height: 25px;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .container {
    font-size: 16px;
    width: 20%;
    @media (max-width: 1000px) {
      width: 46%;
    }
    @media (max-width: 600px) {
      width: 80%;
    }
  }
  .line {
    border-top: 2px solid #3B3A2E; /* Add a horizontal line with a light gray color */
    padding: 10px 0;
  }
.text-item{
  margin-bottom: 2%;
  font-size: 18px;
  span {
    font-size: 16px;
  }
}
  
`;
export default StyledStories;