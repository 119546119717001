import React from 'react';
import StyledStories from './index.styles';
const successStories = [
  {
    id: 1,
    content: "I've been practicing yoga for years, but I've never felt as connected to the practice as I do now with this studio. The variety of classes offered keeps me engaged, and the instructors are not only knowledgeable but also passionate about what they do. The sense of community here is remarkable, and I've made some wonderful friends along the way. Yoga has become an essential part of my life, thanks to this amazing place.",
    text: " Yoga Student",
    location: 'Austin, TX, USA'
  },
  {
    id: 2,
    content: "I can't express how grateful I am to have found this yoga studio. The instructors are not only highly skilled but also incredibly supportive. Yoga has transformed my life, and I credit this studio for making it such a positive experience. I've become more flexible, less stressed, and more in tune with my body. It's a welcoming and peaceful sanctuary that I look forward to visiting every day!",
    text: " Yoga Student",
    location: 'Chennai, INDIA'
  },
  {
    id: 3,
    content: "I stumbled upon this yoga studio when I was going through a tough time in my life, and it turned out to be a game-changer. The positive energy and mindfulness I've gained from practicing yoga here have helped me navigate life's challenges with grace and strength. The instructors are not just teachers; they're mentors who genuinely care about your growth. I can't recommend this place enough to anyone looking to improve their physical and mental well-being.",
    text: " Yoga Student",
    location: "Australia"
  },
  {
    id: 4,
    content: "As someone who was initially skeptical about yoga, I can say without a doubt that it has changed my life. The instructors at this studio are patient and understanding, which made me feel comfortable from day one. I've experienced significant improvements in my flexibility and overall well-being. Yoga has helped me manage stress and find inner peace. I never thought I'd say this, but I'm now a dedicated yogi, all thanks to this incredible studio!",
    text: " Yoga Student",
    location: 'Delhi, INDIA'
  },
];
const Stories = () => {
  
  return (
    <StyledStories>
      <div className="stories">Yoga Success Stories</div>
      <div className="h_line"></div>
      <div className="success">
        {successStories.map((story) => (
          <div key={story.id} className="container">
            <div className="text-item">
              {story.text}
              <br />
              <span>{story.location}</span>
            </div> {/* Add margin-bottom for gap */}
            {story.id <= 4 && <div className="line"></div>}
            <p dangerouslySetInnerHTML={{ __html: story.content }} />
          </div>
        ))}
      </div>
    </StyledStories>
  );
}

export default Stories;