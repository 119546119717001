import styled from "styled-components";
const StyledOffer = styled.div`

    background-color: #DDDBCA;
    padding: 5%;
    /* align-items:center; */
 
  .offer {
    font-size: 60px;
    padding:80px;
    text-align: center;
  }
  .offer-image{
    width:100%;
    object-fit: cover;
    }
  .yoga-offer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .yoga-container {
    display: flex;
    flex-direction: column;
    width: 22%;
    align-items: center;
    justify-content: start;
    box-shadow: 0rem 0.25rem 1.75rem rgba(30, 34, 40, 0.07);
    @media (max-width: 1000px) {
      width: 46%;
      margin-bottom: 5%;
    }
    @media (max-width: 600px) {
      width: 80%;
    }
    .h {
      font-size: 26px;
      color: #79765C;
      padding-top: 5%;
      font-weight: 600;
    }
    .a {
      font-size: 16px;
      padding: 5%;
      line-height: 26px;
      text-align: justify;
    }
  } 
  
`;
export default StyledOffer;