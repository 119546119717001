import React from 'react'
import StyledAddress from './index.styles';
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

const Address = () => {
  return (
    <StyledAddress>
        <div className="address">
              <div className="content">
                <div>
                <div className='studio-container'>
                <div className="Main">Main Studio</div>
                <div className="city">
                  <div className="subtitle">Address:</div>
                     <div className="add"><div>Ff3 Sree Mansion,</div><div> N-9, Rathanambal Street,</div> 
                     <div>Rangarajapuram,</div>
                     <div>Chennai-24,</div><div>Tamil Nadu, India.</div></div>
                  <div className="subtitle">Phone:</div>
                     <div className="num">+91-9884035028</div>
                  <div className="subtitle">Email:</div>
                     <div className="mail">anitharaghu99@yahoo.com</div>
              </div>
              </div>
                  <div className='studio-container'>
                  <div  className="Main">Studio Hours</div>
                  <div className="city">
                      <div>Monday to Friday 6.00 AM to 7.00 PM</div>
                      <div>Special classes available upon request.</div>
                    </div>
                </div>
                </div>
               
              </div>
            <div className='image-icon-container'>
                <img  className="image" src="\images\Addressimage.png" alt="inquireimage"></img>
                <div className='icon-container'>
                <div className="social">Get Social</div>
                <div className='icons'>
                  <a href='https://www.facebook.com/profile.php?id=100063552447093' className='facebook'>
                    < FaFacebookF style={{ fontSize: '24px', color:"#4267B2" }}/>
                  </a>
                  <FaTwitter style={{ fontSize: '34px',color:"black" }}/>
                  <FaInstagram style={{ fontSize: '34px',color:"black" }}/>
                </div>
                </div>
            </div>
        </div>
    </StyledAddress>
  )
}
export default Address