
import styled from "styled-components";
const StyledAcademy = styled.div`
  background-color: #F7F3EB;
  .brand {
    display: flex;
    padding: 8% 2%;
    /* align-items:center; */
    max-width: 1200px;
    margin: auto;
    justify-content:space-around;
    flex-wrap: wrap;
  }

  .heading {
    font-size: 2.5rem;
    color: #79765C;
    margin-bottom: 10%;
  }
  .yoga {
    font-size: 3.5rem;
    font-weight: bold;
    color: #3B3A2E;
  }
  .academy {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  .yoga-image {
    width: 55%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .yoga-avr {
    width: 45%;
    @media (max-width: 767px) {
      width: 100%;
      text-align: center;
    }
  }
  .avr-yogaimage{
    // width:300px;
    margin-bottom: 10%;
  }
  .avr-yogaimage2{
    // width: 300px;
    margin-top: 10%;
  }
  .video {
    width: 80%;
    object-fit: cover;
  }
`;
export default StyledAcademy;