import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import React, {useState} from 'react'
import { useForm } from "react-hook-form";
import emaljs from '@emailjs/browser';
import {FaCheck} from 'react-icons/fa';
import StyledInquire from './index.styles';
import { EMAILSETTINGS } from '../../constants';

import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';


const IMAGES = [
    "\\service1.jpeg",
    "\\service2.jpeg",
    "\\service3.jpeg",
    "\\service4.jpeg",
]

const ImageCarousel = () => {
  return (
    <MDBCarousel className='image-carousel' showIndicators showControls fade>
        {IMAGES.map((img, key) => (
            <MDBCarouselItem
                key={key}
                className='w-100 d-block '
                itemId={key+1}
                src={img}
                alt='...'
            >
            </MDBCarouselItem>
        ))}
    </MDBCarousel>
  );
}

const ContactForm = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const [isUserTyping, setUserTyping] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = (data) => {
    setUserTyping(false);
    const { serviceId, templateId, publicKey } = EMAILSETTINGS;
    emaljs.send(serviceId, templateId, data, publicKey);
    setSubmitted(true);
    reset();
  };
  const handleInputFocus = () => {
    setUserTyping(true);
  };
  return (
    <form className='flex-col flex-center' onSubmit={handleSubmit(onSubmit)}>
      {isSubmitted && !isUserTyping && Object.keys(errors).length === 0 &&
      <div className='success flex-row'>
        <FaCheck className='success-icon' size={18} color='#270' />
        <div>Thank you for contacting us! We will be in touch with you shortly.</div>
      </div>
      }
      <div className='flex-col'>
        <label className='label'>Full Name:</label>
        <input
          className='input'
          onFocus={handleInputFocus}
          placeholder='Please enter your name'
          {...register("name", {
            required: "Name is required",
            pattern: {
              value: /^[a-zA-Z ]*$/,
              message: "Name should contain only alphabets and spaces",
            }
          })
        } />
        {errors.name && <div className='error-text'>{errors.name.message}</div>}
      </div>
      <label className='label'>Email:</label>
      <input 
        className='input'
        placeholder='Please enter your email'
        onFocus={handleInputFocus}
        {...register("email", {
          required: "Email is required",
          pattern: {
            value: /[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/,
            message: "Please enter valid email",
          }
        })} />
      {errors.email && <div className='error-text'>{errors.email.message}</div>}
      <label className='label'>Phone Number:</label>
      <input
        className='input'
        placeholder='Please enter your phone number'
        onFocus={handleInputFocus}
        {...register("phone", {
          required: "Phone Number is required",
          pattern: {
            value: /^(\([0-9]{3}\)|[0-9]{3})[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/,
            message: "Please enter valid phone number",
          }
        })} />
      {errors.phone && <div className='error-text'>{errors.phone.message}</div>}
      <label className='label'>Message:</label>
      <textarea
        rows="4"
        cols="50"
        className='input'
        placeholder='Please enter your message'
        onFocus={handleInputFocus}
        {...register("message", {
          required: "Message is required",
        })} />
      {errors.message && <div className='error-text'>{errors.message.message}</div>}
      <input
          name="submit"
          type="submit"
          value="Send"
          className="button"
      />
    </form>
  );
};


const Inquire = () => {
  return (
    <StyledInquire>
      <div className="inquire">
          <ImageCarousel />
          {/* <img className="image" src="\images\sunset.jpg" alt="inquireimage" /> */}
          <div className="personalized">
             <div className="classes">Personalized Classes</div>
             <div className="content">We offer classes for all types of people. Below is a form to inquire about our classes:</div>
          <ContactForm />
          </div>
      </div>
    </StyledInquire>
  );
};
export default Inquire;