import React from 'react'
import StyledYogasoul from './index.styles';

const Yogasoul = () => {
  return (
    <StyledYogasoul>
      <div className="image-container">
      <img  className="image" src="/yogaicon.png" alt="inquireimage"></img>
      </div>
    </StyledYogasoul>
  )
}
export default Yogasoul